import React from "react";
// import { Link } from "gatsby";

const banners = [
    // {
    // 	link: 'http://bit.ly/CTFlorinPop',
    // 	text:
    // 		"It's Black Friday on Creative-Tim. Get 95% off your purchase! Limited number available. Click here."
    // },
    // {
    // 	link: 'http://bit.ly/WBES6FP',
    // 	text: 'Learn JavaScript ES6 with Wes Bos. Click here.'
    // },
    // {
    // 	link: 'http://bit.ly/WBReactFP',
    // 	text: 'Learn React with Wes Bos. Click here.'
    // },
    // {
    // 	link: 'http://bit.ly/WBNodeFP',
    // 	text: 'Learn NodeJS with Wes Bos. Click here.'
    // },
    // {
    // 	link: 'http://bit.ly/WBES6FP',
    // 	text:
    // 		'Learn JavaScript, NodeJS, ReactJS, CSS and much more from the ground up with Wes Bos.'
    // }
    // {
    // 	link: '/courses',
    // 	text:
    // 		'Learn JavaScript, NodeJS, ReactJS, CSS and much more from the ground up with Wes Bos.'
    // },
    // {
    // 	link: 'https://www.youtube.com/florinpop/?sub_confirmation=1',
    // 	text:
    // 		'We are close to 20k subscribes on YouTube. Help us reach the goal! 🤩',
    // },
    // {
    //  link: "https://bit.ly/3oTgr0O",
    //  text: '👉 Get my eBook "Ten++ Ways to Make Money as a Developer" 👈',
    // },
    {
        link: "https://iCodeThis.com/?ref=florin-pop-site",
        text: "👉 Daily projects to improve your coding skills 👈"
    },
    // {
    //     link: "https://gum.co/makemoneydev/poppy25",
    //     text:
    //         '🎁 Only today! 25% OFF my eBook "Ten++ Ways to Make Money as a Developer" 👈',
    // },
    // {
    //     link: "https://bit.ly/50Projects50Days",
    //     text:
    //         "👉 Build 50 Projects in 50 Days - HTML, CSS and JavaScript Course - Promo Code Applied 👈",
    // },
];

const getRandomBanner = () => {
    return banners[Math.floor(Math.random() * banners.length)];
};

const Banner = () => {
    const banner = getRandomBanner();
    return (
        <a
            href={banner.link}
            target="_blank"
            rel="noopener noreferrer"
            className="banner-container"
        >
            <h3 className="banner-title">{banner.text}</h3>
        </a>
    );
};

export default Banner;
