import React from 'react';

const ADS_TYPES = {
	PATREON: 'patreon',
	COURSES: 'courses',
	WEB_PRODUCTS: 'web'
};

const ads = [
	{
		provider: 'Creative-Tim',
		image: 'https://s3.amazonaws.com/creativetim_bucket/new_logo.png',
		description:
			'UI Kits, Templates and Dashboards built on top of <strong>Bootstrap</strong>, <strong>Vue.js</strong>, <strong>React</strong>, <strong>Angular</strong> and <strong>Node.js</strong>.',
		link: 'http://bit.ly/CTFlorinPop',
		type: ADS_TYPES.WEB_PRODUCTS
	},
	{
		provider: 'Patreon @florinpop17',
		image:
			'https://upload.wikimedia.org/wikipedia/commons/thumb/9/94/Patreon_logo.svg/541px-Patreon_logo.svg.png',
		description:
			"Become part of the awesome community of like-minded web developers that I'm leading by becoming a patron.",
		link: 'http://bit.ly/PatreonFlorinPop',
		type: ADS_TYPES.PATREON
	}
];

class CustomAds extends React.Component {
	state = {
		showAd: true
	};

	componentDidMount = () => {
		const hideAd = JSON.parse(window.sessionStorage.getItem('hideAd'));
		if (hideAd) {
			this.setState({
				showAd: false
			});
		}
	};

	hideAd = () => {
		window.sessionStorage.setItem('hideAd', JSON.stringify(true));
		this.setState({
			showAd: false
		});
	};

	render() {
		const { showAd } = this.state;

		// Get a random ad
		const ad = ads[Math.floor(Math.random() * ads.length)];

		if (!showAd) {
			return null;
		}

		return (
			<div className='custom_ad_container'>
				<div className='custom_ad_inner_container'>
					<button className='close_btn' onClick={this.hideAd}>
						close
					</button>
					<a
						className='custom_ad'
						target='_blank'
						href={ad.link}
						rel='noopener noreferrer'>
						<div className='custom_ads_left'>
							<img src={ad.image} alt={ad.provider} />
						</div>
						<div className='custom_ads_right'>
							<h4>{ad.provider}</h4>
							<p dangerouslySetInnerHTML={{ __html: ad.description }} />
						</div>
					</a>
				</div>
			</div>
		);
	}
}

export default CustomAds;
