/* eslint-disable */
import React from "react";
import { Link } from "gatsby";
import avatarImg from "../../static/images/avatar.jpg";

const Sidebar = () => (
    <aside className="col-sm-2 col-xs-12 main-sidebar hidden-on-small">
        <div className="text-center column">
            <header>
                <Link to="/">
                    <img src={avatarImg} alt="avatar Florin Pop" />
                </Link>
                <h5>Florin Pop</h5>
            </header>
            <section>
                <ul className="navigation-container">
                    <li>
                        <Link to="/blog">Blog</Link>
                    </li>
                    <li>
                        <a
                            href="https://www.youtube.com/channel/UCeU-1X402kT-JlLdAitxSMA"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Videos
                        </a>
                    </li>
                    <li>
                        <a
                            href="https://florinpop17.gumroad.com/l/makemoneydev"
                            target="_blank"
                            rel="noopener noreferrer"
                            >
                            Make Money <br />
                            As a Dev
                        </a>
                    </li>
                    <li>
                        <Link to="/timeline">Timeline</Link>
                    </li>
                    <li>
                        <Link to="/contact">Contact</Link>
                    </li>
                </ul>
            </section>
            <footer>
                {/* <a
                    target="_blank"
                    href="http://eepurl.com/c28cHT"
                    className="tut-btn"
                    rel="noopener noreferrer"
                    title="Subscribe to my emailing list"
                >
                    Get tutorials in your inbox
                </a>
                <br /> */}
                {/* <a
					target='_blank'
					className='patreon-btn'
					rel='noopener noreferrer'
					title='Become a Patron'
					href='https://www.patreon.com/bePatron?u=3432793'>
					<span>
						<svg viewBox='0 0 569 546' xmlns='http://www.w3.org/2000/svg'>
							<g>
								<circle
									data-fill='1'
									id='Oval'
									cx='362.589996'
									cy='204.589996'
									r='204.589996'
								/>
								<rect
									data-fill='2'
									id='Rectangle'
									x='0'
									y='0'
									width='100'
									height='545.799988'
								/>
							</g>
						</svg>
					</span>
					Become a Patron!
				</a> */}
		{/*<a
                    target="_blank"
                    className="discord-btn"
                    rel="noopener noreferrer"
                    title="Join our Discord Community"
                    href="https://discord.gg/qSse3Ey"
                >
                    Join our Discord
                </a>
                <br />*/}
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://twitter.com/@florinpop1705"
                    className="twitter-btn"
                    title="Follow @florinpop1705 on Twitter"
                >
                    <i className="fa fa-twitter" /> Follow @florinpop1705
                </a>
                <ul className="social-container">
                    <li>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://github.com/florinpop17"
                            title="Check out my work on Github"
                        >
                            <i className="fa fa-github" />
                        </a>
                    </li>
                    <li>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://codepen.io/FlorinPop17/"
                            title="Check out my work on Codepen"
                        >
                            <i className="fa fa-codepen" />
                        </a>
                    </li>
                    <li>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="https://linkedin.com/in/florinpop17"
                            title="Connect on Linkedin"
                        >
                            <i className="fa fa-linkedin" />
                        </a>
                    </li>
                    <li>
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href="skype:florinpop1705?chat"
                            title="Let's talk on Skype"
                        >
                            <i className="fa fa-skype" />
                        </a>
                    </li>
                    {/*<li>
                        <a target="_blank" rel='noopener noreferrer' href="https://www.facebook.com/florinpop1705">
                            <i className="fa fa-facebook" />
                        </a>
                    </li>*/}
                </ul>
                <p>Copyright @ {new Date().getFullYear()}</p>
            </footer>
        </div>
    </aside>
);

export default Sidebar;
