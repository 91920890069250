import React from 'react';

const SocialMedia = ({ url, title }) => (
    <div className="social-media-container">
        <ul>
            <li>
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://twitter.com/share?url=${url}&text="${title}" by @florinpop1705`}
                    title="Share on Twitter"
                >
                    <i className="fa fa-twitter" />
                </a>
            </li>
            <li>
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://www.linkedin.com/shareArticle?mini=true&url=${url}&title=${title}`}
                    title="Share on Linkedin"
                >
                    <i className="fa fa-linkedin" />
                </a>
            </li>
            <li>
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`https://www.facebook.com/sharer/sharer.php?u=${url}&t=${title}`}
                    title="Share on Facebook"
                >
                    <i className="fa fa-facebook" />
                </a>
            </li>
        </ul>
    </div>
);

export default SocialMedia;
