/* eslint-disable */
import React, { Component } from 'react';
import { Link } from 'gatsby';

class MobileMenu extends Component {
	toggleMenu = () => {
		const { menu } = this.refs;

		// check if menu has class closed
		if (menu.classList.value.includes('open')) {
			menu.classList.remove('open');
		} else {
			menu.classList.add('open');
		}
	};

	render() {
		return (
			<div ref='menu' className='mobile-menu-container'>
				<nav className='mobile-menu'>
					<ul>
						<li>
							<Link to='/blog'>Blog</Link>
						</li>
						<li>
							<a
								href='https://www.youtube.com/channel/UCeU-1X402kT-JlLdAitxSMA'
								target='_blank'
								rel='noopener noreferrer'>
								Videos
							</a>
						</li>
						<li>
							<a
								href="https://makemoney.dev"
								target="_blank"
								rel="noopener noreferrer"
								>
								Make Money <br />
								As a Dev
							</a>
						</li>
						<li>
							<Link to='/timeline'>Timeline</Link>
						</li>
						<li>
							<Link to='/contact'>Contact</Link>
						</li>
					</ul>
					<button onClick={this.toggleMenu}>
						<span className='line line-top' />
						<span className='line line-bottom' />
					</button>
				</nav>
				<div className='circle' />
			</div>
		);
	}
}

export default MobileMenu;
