import React from 'react';

const MobileFooter = () => (
    <footer className="mobile-footer">
        <a
            target="_blank"
            href="http://eepurl.com/c28cHT"
            className="tut-btn"
            rel="noopener noreferrer"
            title="Subscribe to my emailing list"
        >
            Get tutorials in your inbox
        </a>
        <br />
        <a
            target="_blank"
            className="patreon-btn"
            rel="noopener noreferrer"
            title="Become a Patron"
            href="https://www.patreon.com/bePatron?u=3432793"
        >
            <span>
                <svg viewBox="0 0 569 546" xmlns="http://www.w3.org/2000/svg">
                    <g>
                        <circle
                            data-fill="1"
                            id="Oval"
                            cx="362.589996"
                            cy="204.589996"
                            r="204.589996"
                        />
                        <rect
                            data-fill="2"
                            id="Rectangle"
                            x="0"
                            y="0"
                            width="100"
                            height="545.799988"
                        />
                    </g>
                </svg>
            </span>
            Become a Patron!
        </a>
        <br />
        <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/@florinpop1705"
            className="twitter-btn"
        >
            <i className="fa fa-twitter" /> Follow @florinpop1705
        </a>
        <ul className="social-container">
            <li>
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://github.com/florinpop17"
                >
                    <i className="fa fa-github" />
                </a>
            </li>
            <li>
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://codepen.io/FlorinPop17/"
                >
                    <i className="fa fa-codepen" />
                </a>
            </li>
            <li>
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://linkedin.com/in/florinpop17"
                >
                    <i className="fa fa-linkedin" />
                </a>
            </li>
            <li>
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="skype:florinpop1705?chat"
                >
                    <i className="fa fa-skype" />
                </a>
            </li>
            {/*<li>
                <a target="_blank" rel='noopener noreferrer' href="https://www.facebook.com/florinpop1705">
                    <i className="fa fa-facebook" />
                </a>
            </li>*/}
        </ul>
        <p>Copyright @ {new Date().getFullYear()}, Florin Pop</p>
    </footer>
);

export default MobileFooter;
