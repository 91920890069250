import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Helmet from "react-helmet";

import Sidebar from "./Sidebar";
import MobileMenu from "./MobileMenu";
import MobileFooter from "./MobileFooter";
import Main from "./Main";
// import { CustomAds } from '../Other/';

import favicon from "../../static/images/favicon.ico";
import "../../static/style/prism.min.css";
import "../../static/style/fontello.min.css";
import "../../static/style/style.css";

const Layout = ({ children }) => (
    <StaticQuery
        query={graphql`
            query SiteTitleQuery {
                site {
                    siteMetadata {
                        title
                    }
                }
            }
        `}
        render={(data) => (
            <div className="page-container">
                <Helmet>
                    <title>{data.site.siteMetadata.title}</title>
                    <meta
                        name="description"
                        content="Front-end Developer & Blogger"
                    />
                    <meta name="author" content="Florin Pop" />
                    <meta
                        name="keywords"
                        content="Front-end developer, JavaScript developer, HTML, HTML5, CSS3, CSS, JavaScript, ReactJS, NodeJS, React, Node, Express, NextJS, MongoDB, Firebase, jQuery, Florin Pop, Blog, Florin Pop Blog, JavaScript Blog, CSS Blog, HTML Blog"
                    />

                    <link
                        rel="shortcut icon"
                        type="image/x-icon"
                        href={favicon}
                    />
                    <link
                        rel="stylesheet"
                        href="https://cdnjs.cloudflare.com/ajax/libs/normalize/7.0.0/normalize.min.css"
                    />
                    <link
                        rel="stylesheet"
                        href="https://cdnjs.cloudflare.com/ajax/libs/flexboxgrid/6.3.1/flexboxgrid.min.css"
                    />
                    {/* <link
                        rel="stylesheet"
                        href="https://cdn.rawgit.com/konpa/devicon/df6431e323547add1b4cf45992913f15286456d3/devicon.min.css"
                    /> */}

                    {/* MONETIZATION SCRIPT FOR BUYSELLADS */}
                    {/* <script src='//m.servedby-buysellads.com/monetization.js' type='text/javascript'></script> */}
                    {/* <script
                        data-ad-client="ca-pub-3439028499851395"
                        async
                        src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
                    ></script> */}
                    <script
                        defer
                        data-domain="florin-pop.com"
                        src="https://plausible.io/js/plausible.js"
                    ></script>
                </Helmet>
                <div className="row">
                    <Sidebar />
                    <Main>{children}</Main>
                    <MobileMenu />
                    {/* <CustomAds /> */}
                </div>
                <MobileFooter />
            </div>
        )}
    />
);

export default Layout;
