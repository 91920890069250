import React from "react";
import { Banner, /* Carbon, CarbonNative */ } from "../Other";

const Main = (props) => (
    <main className="col-md-10 col-xs-12 main-content">
        <Banner />
        {/* <Carbon carbonUrl="//cdn.carbonads.com/carbon.js?serve=CK7ICKJI&placement=wwwflorin-popcom" /> */}
        {/* <CarbonNative /> */}
        <main>{props.children}</main>
    </main>
);

export default Main;
