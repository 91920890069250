import _ from 'lodash';
import React, { Component } from 'react';

class CarbonNative extends Component {
	constructor(props) {
		super(props);

		this.name = this.props.name || 'wwwflorin-popcom';
		this.script = this.props.script || null;
	}

	adShowing = () => document.querySelector(`#_stickybox_`) !== null;

	componentDidMount = () => {
		let script = document.createElement('script');
		script.defer = !!this.script;
		script.async = true;
		script.type = 'text/javascript';
		script.src =
			this.script || `https://florin-pop.com/scripts/carbon_native.js`;

		script.onerror = () => {
			this.forceUpdate();
		};

		script.addEventListener('load', () => {
			if (!this.adShowing()) {
				_.invoke(window._bsa, 'refresh');
			}
		});

		document.querySelector(`body`).appendChild(script);
	};

	render() {
		return (
			<>
				<div className='native-js' />
			</>
		);
	}
}

export default CarbonNative;
